import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "ul" ]

  initialize() {
    for (var key in Cookies.get()) {
      if(key.includes("btn")) {
        if(Cookies.get(key) === 'true') {
          let ul = document.querySelector(`ul[data-btn=${key}]`);
          ul.classList.add('show');
        }
      }
    }
  }

  connect() {

  }

  toggleSubmenu() {
    let item = event.target;
    setTimeout(() => {
      if(item.getAttribute('aria-expanded') == 'true') {
        Cookies.set(item.id, item.getAttribute('aria-expanded'))
      } else {
        Cookies.remove(item.id);
      }
    }, 150);
  }
}
