import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    $("select#notify_publishers").selectpicker({width: '100%'});
      $('[data-toggle="tooltip"]').tooltip();
    $('#loading').fadeOut(200);
  }

  fsmDeleteProgramConfirm() {
    let that = this;
    let meeting = event.target.dataset.meeting;
    let program = event.target.dataset.program;
    let row = event.target.closest('tr');

    bootbox.dialog({
      title: I18n.t('fsmeeting.fsmeeting'),
      message: I18n.t('fsmeeting.confirm.delete'),
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/fieldservice/${program}/meeting/${meeting}/remove`,
              type: "delete",
              data: "",
              success: function(data) {
                row.remove();
              },
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });

  }
}
