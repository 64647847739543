require("i18n-js");
let locale = document.querySelector("meta[name='locale']").getAttribute('content');

import * as I18n from 'i18n-js'
var locales = require(`locales/${locale}.json`)
I18n.translations = locales;
I18n.defaultLocale = 'en'
I18n.locale = locale;

window.I18n = I18n;
