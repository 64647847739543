import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "ul" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    $(".selectpicker").selectpicker({width: '250px'})
    $('#loading').fadeOut(200);
  }

  toggleMeeting() {
    $('#loading').fadeIn(200);
    let meeting = event.target.dataset.meeting;
    let publisher = event.target.dataset.publisher;
    let checked = event.target.dataset.checked;

    if(checked == 'false') {
      event.target.innerHTML = '<i class="far fa-check-square fa-lg"></i>'
      event.target.setAttribute('data-checked', true);
      checked = true;
    } else {
      event.target.innerHTML = '<i class="far fa-square fa-lg"></i>'
      event.target.setAttribute('data-checked', false);
      checked = false;
    }

    Rails.ajax({
      url: `/${this.locale}/fieldservice/${meeting}/${publisher}/${checked}`,
      type: "post",
      data: "",
      success: function(data) {
        $('#loading').fadeOut(200);
      },
      error: function(data) {
        $('#loading').fadeOut(200);
      }
    });


  }
}
