require("@rails/ujs").start()
require("turbolinks").start()
require("jquery");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/droppable");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/mouse");

require("bootstrap-select");
require("bootbox");
require("moment");
require("js-cookie");

import * as ClassicEditor from '../src/javascripts/ckeditor.js';

import Rails from '@rails/ujs';
import jquery from 'jquery';
import mouse from 'jquery-ui';
import bootbox from 'bootbox';
import moment from 'moment';
import Cookies from 'js-cookie';

import "../src/javascripts/i18n.js.erb";

/*
Cookies.defaults = {
    path: '/',
    secure: true
};
*/

window.Rails = Rails;
window.jquery = jquery;
window.$ = jquery;
window.jQuery = jquery;
window.bootbox = bootbox;
window.moment = moment;
window.Cookies = Cookies;
window.ClassicEditor = ClassicEditor;

import 'bootstrap/dist/js/bootstrap';
import '../src/javascripts/jquery.ui.touch-punch.js';
import '../src/javascripts/jquery.fileupload.js';

import '../src/javascripts/meetinghelper.js';

require("tempusdominus-bootstrap-4");
require("bootstrap-confirmation2");

import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
import 'jquery-ui/ui/widgets/sortable.js';

import 'moment/locale/es.js';
import 'moment/locale/it.js';
import 'moment/locale/de.js';

moment.updateLocale('en', {
  week : {
    dow : 1
  },
  longDateFormat : {
    L: "DD/MM/YYYY",
    l: "D/M/YYYY",
  }
});

import "controllers"
