import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "ul" ]

  connect() {
    $(".selectpicker").selectpicker();
    $('#loading').fadeOut(200);
  }

}
